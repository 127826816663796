const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/terms-and-conditions"],
    exact: true,
    component: "Terms-and-Conditions",
  },
];

export default routes;
